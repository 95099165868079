/* styles.css */
.max-w-6xl {
    max-width: 80rem;
  }
  
  @media (max-width: 768px) {
    .min-h-screen {
      min-height: 50vh;
    }
    
    .flex {
      flex-direction: column;
    }
    
    .max-w-2xl {
      text-align: center;
    }
    
    .relative {
      margin-top: -40px;
    }
    
    .w-[500px] {
      width: 80%;
    }
    
    .mx-auto {
      margin: 0 auto;
    }
    
    .archivo-black-regular {
      font-size: 1.5rem;
    }
  
    .text-3xl {
      font-size: 1.875rem;
    }
    
    .gap-10 {
      gap: 20px;
    }
  }
  
.text-gradient {
    background: linear-gradient(to right, #ff2400, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}