@keyframes glowing {
    0% {
        border-color: white;
        box-shadow: 0 0 5px white;
    }
    50% {
        border-color: rgb(255, 94, 46);
        box-shadow: 0 0 20px rgb(243, 83, 43);
    }
    100% {
        border-color: white;
        box-shadow: 0 0 5px white;
    }
}

.blog-button {
    border: 2px solid;
    animation: glowing 1.5s infinite;
    transition: background-color 0.3s, color 0.3s;
}
