@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(4);
      opacity: 0;
    }
  }
  
  .pulse-circle {
    position: relative;
    width: 2rem; /* Adjust size based on icon */
    height: 2rem;
    display: inline-block;
  }
  
  .pulse-circle::before,
  .pulse-circle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(0, 123, 255, 0.5); /* Circle color (adjust as needed) */
    transform: translate(-50%, -50%);
    animation: pulse 2s infinite ease-in-out;
  }
  
  .pulse-circle::after {
    animation-delay: 1s; /* Delay the second circle */
  }
  