.max-w-6xl {
  max-width: 80rem;
}

@media (max-width: 768px) {
  .min-h-screen {
      min-height: 50vh;
  }

  .flex {
      flex-direction: column;
  }

  .max-w-2xl {
      text-align: center;
  }

  /* Remove transform and add margin adjustment */
  .relative {
      margin-top: -20px; /* Adjust margin for medium screens */
  }

  .w-[500px] {
      width: 80%;
  }

  .mx-auto {
      margin: 0 auto;
  }

  .archivo-black-regular {
      font-size: 1.5rem;
  }

  .text-3xl {
      font-size: 1.875rem;
  }

  .gap-10 {
      gap: 20px;
  }
}

.text-gradient {
  background: linear-gradient(to left, #ff2400 0%, #ff2400 40%, #0000ff 60%, #0000ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes handWave {
    0%, 100% {
        transform: translateX(0) scale(1);
    }
    50% {
        transform: translateX(5px) scale(1.1); /* Slightly moves and scales the icon */
    }
}

.hand-animate {
    animation: handWave 0.8s infinite; /* Adjust duration as needed */
}
